/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserCompany } from './user-company';
import { Task } from './task';
import { CompanyGroup } from './company-group';
import { JobCategory } from './job-category';
import { Permission } from './permission';

export interface Participant {
  readonly id: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  readonly fullname: string;
  readonly company: UserCompany;
  /**
   * * `owner` - Owner * `admin` - Admin * `supervisor` - Supervisor * `user` - User * `employee` - Employee * `nobody` - Nobody
   */
  role?: Participant.RoleEnum;
  job_category?: JobCategory;
  readonly last_login: string | null;
  readonly date_joined: string;
  avatar: string | null;
  groups?: Array<CompanyGroup>;
  readonly permissions: Array<Permission>;
  readonly effective_permissions: Array<Permission>;
  readonly start: string | null;
  readonly task: Task;
}
export namespace Participant {
  export type RoleEnum =
    | 'owner'
    | 'admin'
    | 'supervisor'
    | 'user'
    | 'employee'
    | 'nobody';
  export const RoleEnum = {
    Owner: 'owner' as RoleEnum,
    Admin: 'admin' as RoleEnum,
    Supervisor: 'supervisor' as RoleEnum,
    User: 'user' as RoleEnum,
    Employee: 'employee' as RoleEnum,
    Nobody: 'nobody' as RoleEnum,
  };
}
