/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PublicTrainingRequest } from './public-training-request';

export interface CertificateRequest {
  training: PublicTrainingRequest;
  user: number;
  valid_until?: string | null;
  /**
   * * `passed` - Passed * `failed` - Failed * `past_due` - Past due
   */
  result?: CertificateRequest.ResultEnum;
  qr_code: string;
  url?: string | null;
}
export namespace CertificateRequest {
  export type ResultEnum = 'passed' | 'failed' | 'past_due';
  export const ResultEnum = {
    Passed: 'passed' as ResultEnum,
    Failed: 'failed' as ResultEnum,
    PastDue: 'past_due' as ResultEnum,
  };
}
