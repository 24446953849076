<div class="h-full flex flex-col">
  @if (loading || rendering) {
    <mat-spinner
      class="self-center"
      [strokeWidth]="3"
      [diameter]="50"
    ></mat-spinner>
  }
  @if (fileType === 'video' && url) {
    <cat-ai-video-player [videoUrl]="url"></cat-ai-video-player>
  }

  @if (fileType === 'document' && url) {
    <pdf-viewer
      [src]="url"
      class="w-full grow bg-white rounded-lg"
      (page-rendered)="onSuccessRender()"
      (error)="onError($event)"
      [zoom-scale]="'page-width'"
    ></pdf-viewer>
  }
  @if (fileType === undefined) {
    <div class="text-center align-middle">
      <p>Wrong file extension</p>
    </div>
  }
</div>
