import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNameAbbr',
  standalone: true,
})
export class GetNameAbbrPipe implements PipeTransform {
  transform(value: string) {
    return value
      .split(' ')
      .map((part) => part[0])
      .join('')
      .toUpperCase();
  }
}
