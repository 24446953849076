import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TableFooterComponent } from '../table-footer/table-footer.component';
import {
  PaginationConfig,
  TableConfig,
  GetTableValueByKeyPipe,
  GetTableComponentInputsPipe,
} from '@cat-ai-us-fe/shared/util';
import { TableActionsComponent } from '../table-actions/table-actions.component';
import { NgComponentOutlet } from '@angular/common';
import { RoleAccessDirective } from '@cat-ai-us-fe/shared/data-access';

@Component({
  selector: 'cat-ai-table',
  standalone: true,
  imports: [
    TableFooterComponent,
    TableActionsComponent,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    GetTableValueByKeyPipe,
    GetTableComponentInputsPipe,
    NgComponentOutlet,
    RoleAccessDirective,
  ],
  templateUrl: './table.component.html',
})
export class TableComponent<Row extends object> implements OnChanges {
  @Input({ required: true }) rows!: Row[] | null;
  @Input({ required: true }) config!: TableConfig<Row>;
  @Input() isLoading = false;
  @Input() wrapperClasses = '';
  @Input() tableClasses = '';

  @Output() pageChange = new EventEmitter<number>();
  @Output() perPageChange = new EventEmitter<number>();
  @Output() selectedRowsChange: EventEmitter<Row[]> = new EventEmitter<Row[]>();
  @Output() rowClick: EventEmitter<Row> = new EventEmitter<Row>();
  @Output() edit = new EventEmitter<Row>();
  @Output() print = new EventEmitter<Row>();
  @Output() remove = new EventEmitter<Row>();
  @Output() search = new EventEmitter<Row>();
  @Output() archive = new EventEmitter<Row>();
  @Output() viewDocument = new EventEmitter<Row>();
  @Output() download = new EventEmitter<Row>();
  visibleColumns: number[] = [];
  selectedRows: Row[] = [];
  actionsEnabled!: boolean;
  skeletons = [];
  pagination!: PaginationConfig;

  ngOnChanges(): void {
    this.setActions();
    this.setSceletons();
    this.setColumns();
    this.setPagination();
  }

  changePage(page: number) {
    this.config.pagination.page = page;
    this.pageChange.emit(page);
  }

  changePerPage(perPage: number) {
    this.config.pagination.perPage = perPage;
    this.perPageChange.emit(perPage);
  }

  toggleRowSelection(checked: boolean, row: Row) {
    checked
      ? this.selectedRows.push(row)
      : this.selectedRows.splice(this.selectedRows.indexOf(row), 1);

    this.selectedRowsChange.emit(this.selectedRows);
  }

  masterToggleRowSelection(checked: boolean) {
    if (checked) {
      this.selectedRows = this.rows || [];
    } else {
      this.selectedRows = [];
    }

    this.selectedRowsChange.emit(this.selectedRows);
  }

  toggleColumnVisibility(index: number) {
    const visible = this.visibleColumns.includes(index);
    if (visible) {
      this.visibleColumns.splice(this.visibleColumns.indexOf(index), 1);
    } else {
      this.visibleColumns.push(index);
    }
  }

  private setActions() {
    this.actionsEnabled =
      !!this.config.actions &&
      Object.keys(this.config.actions).every((key: string) => {
        return !!(this.config.actions as any)[key];
      });
  }

  private setSceletons() {
    const length = this.config.pagination.perPage || 12;
    this.skeletons = Array.from({ length });
  }

  private setColumns() {
    this.visibleColumns = this.config.columns.map((_, i) => i);
  }

  private setPagination() {
    this.pagination = { ...this.config.pagination };
  }
}
