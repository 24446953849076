import { Injectable } from '@angular/core';
import { CertificatesService as ApiCertificatesService } from '@cat-ai-us-fe/api';

@Injectable({
  providedIn: 'root',
})
export class CertificatesService {
  constructor(private readonly certificatesService: ApiCertificatesService) {}

  getCertificate(uuid: string) {
    return this.certificatesService.certificatesRetrieve({
      uuid,
    });
  }
}
