export enum VideoExtensions {
  MP4 = 'mp4',
  AVI = 'avi',
  MOV = 'mov',
  WMV = 'wmv',
  FLV = 'flv',
  MKV = 'mkv',
}

export enum DocumentExtensions {
  PDF = 'pdf',
}
