/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ModelFile {
  readonly id: number;
  url: string | null;
  /**
   * * `plain` - Plain * `pdf` - Pdf * `docx` - Docx * `xlsx` - Xlsx * `video` - Generic Video * `mp4` - Mp4 * `mov` - Mov * `avi` - Avi * `wmv` - Wmv * `webm` - Webm * `flv` - Flv * `audio` - Generic Audio * `mp3` - Mp3 * `wav` - Wav * `flac` - Flac * `ogg` - Ogg * `image` - Generic Image * `jpg` - Jpg * `jpeg` - Jpeg * `png` - Png * `gif` - Gif * `bmp` - Bmp * `tiff` - Tiff * `svg` - Svg * `unknown` - Unknown
   */
  extension?: ModelFile.ExtensionEnum;
  /**
   * * `created` - Created * `upload_success` - Upload Success * `upload_failed` - Upload Failed * `parsed_success` - Parsed Success * `parsed_failed` - Parsed Failed
   */
  status?: ModelFile.StatusEnum;
}
export namespace ModelFile {
  export type ExtensionEnum =
    | 'plain'
    | 'pdf'
    | 'docx'
    | 'xlsx'
    | 'video'
    | 'mp4'
    | 'mov'
    | 'avi'
    | 'wmv'
    | 'webm'
    | 'flv'
    | 'audio'
    | 'mp3'
    | 'wav'
    | 'flac'
    | 'ogg'
    | 'image'
    | 'jpg'
    | 'jpeg'
    | 'png'
    | 'gif'
    | 'bmp'
    | 'tiff'
    | 'svg'
    | 'unknown';
  export const ExtensionEnum = {
    Plain: 'plain' as ExtensionEnum,
    Pdf: 'pdf' as ExtensionEnum,
    Docx: 'docx' as ExtensionEnum,
    Xlsx: 'xlsx' as ExtensionEnum,
    Video: 'video' as ExtensionEnum,
    Mp4: 'mp4' as ExtensionEnum,
    Mov: 'mov' as ExtensionEnum,
    Avi: 'avi' as ExtensionEnum,
    Wmv: 'wmv' as ExtensionEnum,
    Webm: 'webm' as ExtensionEnum,
    Flv: 'flv' as ExtensionEnum,
    Audio: 'audio' as ExtensionEnum,
    Mp3: 'mp3' as ExtensionEnum,
    Wav: 'wav' as ExtensionEnum,
    Flac: 'flac' as ExtensionEnum,
    Ogg: 'ogg' as ExtensionEnum,
    Image: 'image' as ExtensionEnum,
    Jpg: 'jpg' as ExtensionEnum,
    Jpeg: 'jpeg' as ExtensionEnum,
    Png: 'png' as ExtensionEnum,
    Gif: 'gif' as ExtensionEnum,
    Bmp: 'bmp' as ExtensionEnum,
    Tiff: 'tiff' as ExtensionEnum,
    Svg: 'svg' as ExtensionEnum,
    Unknown: 'unknown' as ExtensionEnum,
  };
  export type StatusEnum =
    | 'created'
    | 'upload_success'
    | 'upload_failed'
    | 'parsed_success'
    | 'parsed_failed';
  export const StatusEnum = {
    Created: 'created' as StatusEnum,
    UploadSuccess: 'upload_success' as StatusEnum,
    UploadFailed: 'upload_failed' as StatusEnum,
    ParsedSuccess: 'parsed_success' as StatusEnum,
    ParsedFailed: 'parsed_failed' as StatusEnum,
  };
}
