import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableConfig, TableRemoveActionIcon } from '@cat-ai-us-fe/shared/util';
import { ConfirmActionModalComponent } from '../confirm-action-modal/confirm-action-modal.component';

@Component({
  selector: 'cat-ai-table-actions',
  standalone: true,
  imports: [MatButtonModule, MatIcon, MatDialogModule, MatTooltipModule],
  templateUrl: './table-actions.component.html',
})
export class TableActionsComponent<
  Data extends {
    disabledPrintAction?: boolean;
    disabledArchieveAction?: boolean;
  },
> {
  @Input({ required: true }) data!: Data;

  @Input() actions!: TableConfig<Data>['actions'];

  @Input() removeActionIcon?: TableRemoveActionIcon = 'cross';
  @Input() searchActionIcon!: string | undefined;
  @Output() print = new EventEmitter<Data>();
  @Output() edit = new EventEmitter<Data>();
  @Output() remove = new EventEmitter<Data>();
  @Output() search = new EventEmitter<Data>();
  @Output() archive = new EventEmitter<Data>();
  @Output() viewDocument = new EventEmitter<Data>();
  @Output() download = new EventEmitter<Data>();

  readonly dialog = inject(MatDialog);

  deleteAction() {
    this.dialog
      .open(ConfirmActionModalComponent, {
        data: {
          title: 'Delete ',
          text: 'You will permanently delete this element',
          confirmButtonText: 'Delete',
        },
      })
      .afterClosed()
      .subscribe((deleteConfirmed: boolean) => {
        if (deleteConfirmed) {
          this.remove.emit(this.data);
        }
      });
  }
}
