import { NgClass, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { GetNameAbbrPipe } from '@cat-ai-us-fe/shared/util';

@Component({
  selector: 'cat-ai-avatar',
  standalone: true,
  imports: [NgClass, GetNameAbbrPipe, NgOptimizedImage],
  templateUrl: './avatar.component.html',
})
export class AvatarComponent {
  @Input({ required: true }) url: string | null = null;
  @Input({ required: true }) fullName!: string;
  @Input() size: 'default' | 'big' = 'default';

  imageSize = {
    default: 32,
    big: 48,
  };

  getUserAbbr() {
    return this.fullName
      .split(' ')
      .map((str) => str[0])
      .join('')
      .toUpperCase();
  }
}
