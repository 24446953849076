@if (url) {
  <img
    [ngSrc]="url"
    [alt]="fullName"
    [width]="imageSize[size]"
    [height]="imageSize[size]"
    class="rounded-full shrink-0 object-cover object-center"
    [ngClass]="{
      'size-8': size === 'default',
      'size-12': size === 'big',
    }"
  />
} @else {
  <div
    class="bg-gray-50 rounded-full flex justify-center items-center text-gray"
    [ngClass]="{
      'size-8': size === 'default',
      'size-12': size === 'big',
    }"
  >
    {{ fullName | getNameAbbr }}
  </div>
}
