/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Task } from './task';

export interface TrainingCertificate {
  readonly id: number;
  name: string;
  description: string;
  cover: string | null;
  start: string;
  end: string;
  /**
   * * `draft` - Draft * `active` - Active * `completed` - Completed * `failed` - Failed * `archived` - Archived
   */
  status?: TrainingCertificate.StatusEnum;
  certificate_valid_until?: string | null;
  seconds_to_complete?: number | null;
  number_of_attempts?: number;
  pass_score: number;
  readonly created_at: string;
  created_by?: number;
  readonly task: Task;
}
export namespace TrainingCertificate {
  export type StatusEnum =
    | 'draft'
    | 'active'
    | 'completed'
    | 'failed'
    | 'archived';
  export const StatusEnum = {
    Draft: 'draft' as StatusEnum,
    Active: 'active' as StatusEnum,
    Completed: 'completed' as StatusEnum,
    Failed: 'failed' as StatusEnum,
    Archived: 'archived' as StatusEnum,
  };
}
